<template>
  <v-row class="ma-0" align="center">
    <div class="pr-2">
      <v-menu
        :close-on-content-click="true"
        transition="None"
        :close-on-click="true"
        nudge-top="35"
        nudge-right="5"
        offset-x
        v-model="countrySelector"
        offset-y
      >
        <template v-slot:activator="{on, attrs}">
          <div
            v-bind="attrs"
            style="width: 65px; max-height: 35px; height: 35px"
            v-on="on"
          >
            <v-hover v-slot="{hover}">
              <div
                style="
                  width: 65px;
                  max-height: 35px;
                  height: 35px;
                  border-radius: 10px;
                  position: relative;
                  border: 1px solid rgba(220, 220, 220, 1);
                "
                :style="
                  countrySelector
                    ? 'background-color: rgba(220, 220, 220, 1)'
                    : hover
                    ? 'background-color: rgba(220, 220, 220, .3); transition: all 100ms linear'
                    : 'transition: all 100ms linear'
                "
              >
                <v-row
                  class="ma-0 mb-1"
                  style="position: absolute; left: 5px; top: -4px"
                  align="center"
                >
                  <country-flag
                    style="border-radius: 10px; width: 50px; height: 40px"
                    :country="countryList[selectedCountry].id"
                    size="normal"
                  ></country-flag>
                  <v-icon
                    :style="countrySelector ? 'transform: rotate(-90deg)' : ''"
                    class="mt-2 ml-1"
                    >mdi-chevron-down</v-icon
                  >
                </v-row>
              </div>
            </v-hover>
          </div>
        </template>

        <v-list class="pr-1 pl-1">
          <v-list-item-group class="ml-1 mr-1" v-model="selectedCountry">
            <div
              v-for="(country, index) in countryList"
              :key="`plate-country-${index}`"
              style="border-radius: 8px; overflow: hidden; margin-bottom: 4px"
            >
              <v-list-item
                dense
                style="height: 20px"
                v-on:click="selectedCountry = index"
                :ripple="false"
                :style="selectedCountry == index ? 'pointer-events: none' : ''"
              >
                <v-list-item-title>
                  <v-row class="ma-0 mb-2" align="center">
                    <country-flag
                      style="border-radius: 10px; width: 50px; height: 40px"
                      :country="country.id"
                      size="normal"
                    ></country-flag>
                    <span
                      class="mt-2 ml-2 font-weight-bold"
                      :class="selectedCountry == index ? 'primary--text' : ''"
                      style="font-size: 14px"
                      >{{ country.name }}</span
                    >
                  </v-row>
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>

    <div
      v-if="
        countryList[selectedCountry].id == 'DE' ||
        countryList[selectedCountry].id == 'AT' ||
        countryList[selectedCountry].id == 'CH' ||
        countryList[selectedCountry].id == 'LU'
      "
    >
      <input
        class="mr-1"
        :id="`plate-input-${index}`"
        v-model="plateInput1"
        onClick="this.select();"
        onFocus="this.select();"
        :placeholder="countryList[selectedCountry].placeholder[1]"
        type="text"
        maxlength="3"
        style="
          position: relative;
          max-width: 85px;
          text-transform: uppercase;
          text-align: center;
          font-size: 18px;
        "
      />
      <span style="user-select: none; cursor: default">—</span>

      <input
        :id="`plate-input-${index}-1`"
        v-model="plateInput2"
        onClick="this.select();"
        onFocus="this.select();"
        :placeholder="countryList[selectedCountry].placeholder[2]"
        type="text"
        class="mr-1"
        style="
          position: relative;
          max-width: calc(100% - 100px);
          margin-left: 5px;
          text-transform: uppercase;
          text-align: center;
          font-size: 18px;
        "
      />

      <!-- <v-row class="ma-0" align="center" style="position: relative; width: 100%">
    <div style="width: 80px; position: relative; display: inline-block; " class="mr-1 ml-2" >
    <input  :id="`plate-input-${index}`" v-model="plateInput1" onClick="this.select();" onFocus="this.select();" placeholder="GG" type="text" maxlength="3" style="position: relative; width: 100%; text-transform:uppercase;text-align: center; font-size: 18px"/>
    </div>
    <span style="user-select:none; cursor: default">—</span>
    <div style="position: relative; display: inline-block; " class="mr-1 ml-2">
    <input v-model="plateInput2" onClick="this.select();"  placeholder="AT2021" class="ml-1" type="text" maxlength="10" style="position: relative; width: 100%; text-transform:uppercase; font-size: 18px;"/>
    </div>
    </v-row> -->
    </div>

    <div v-else-if="countryList[selectedCountry].id == 'NL'">
      <input
        :id="`plate-input-${index}`"
        v-model="plateInput1"
        onClick="this.select();"
        onFocus="this.select();"
        :placeholder="countryList[selectedCountry].placeholder[1]"
        class="mr-1"
        type="text"
        maxlength="3"
        style="
          text-transform: uppercase;
          width: 90px;
          text-align: center;
          font-size: 18px;
        "
      />
      <span style="user-select: none; cursor: default">—</span>
      <input
        :id="`plate-input-${index}-1`"
        v-model="plateInput2"
        onClick="this.select();"
        onFocus="this.select();"
        :placeholder="countryList[selectedCountry].placeholder[2]"
        class="mr-1 ml-2"
        type="text"
        maxlength="3"
        style="
          text-transform: uppercase;
          width: 90px;
          text-align: center;
          font-size: 18px;
        "
      />
      <span style="user-select: none; cursor: default">—</span>
      <input
        :id="`plate-input-${index}-2`"
        v-model="plateInput3"
        onClick="this.select();"
        onFocus="this.select();"
        :placeholder="countryList[selectedCountry].placeholder[3]"
        class="mr-1 ml-2"
        type="text"
        maxlength="3"
        style="
          text-transform: uppercase;
          width: 90px;
          text-align: center;
          font-size: 18px;
        "
      />
    </div>

    <IconButton
      v-show="removable"
      icon="mdi-close-circle"
      buttonColor="primary"
      class="ml-1"
      dense
    />
  </v-row>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import IconButton from '@components/button/IconButton.vue';

export default {
  name: 'PlateInput',
  props: {
    index: {
      type: Number,
      default: 1,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Number,
      default: 0,
    },
  },

  components: {
    CountryFlag,
    IconButton,
  },
  data() {
    return {
      plateInput1: null,
      plateInput2: null,
      plateInput3: null,
      platePrefix: null,
      plateSuffix: null,
      countryList: [
        {
          id: 'DE',
          name: this.$helper.countryName('DE'),
          placeholder: {
            1: 'GG',
            2: 'AT 123',
          },
        },
        {
          id: 'AT',
          name: this.$helper.countryName('AT'),
          placeholder: {
            1: 'W',
            2: '120 AB',
          },
        },
        {
          id: 'CH',
          name: this.$helper.countryName('CH'),
          placeholder: {
            1: 'LU',
            2: '12345',
          },
        },
        {
          id: 'LU',
          name: this.$helper.countryName('LU'),
          placeholder: {
            1: 'CD',
            2: '1234',
          },
        },
        {
          id: 'NL',
          name: this.$helper.countryName('NL'),
          placeholder: {
            1: '1',
            2: 'ABC',
            3: '23',
          },
        },
      ],
      countrySelector: false,
      selectedCountry: 0,
    };
  },

  watch: {
    selectedCountry: {
      handler() {
        // if (this.selectedCountry == null) this.selectedCountry = 0
        this.emitChangeEvent();
      },
      immediate: true,
      deep: true,
    },
    data: {
      handler() {
        if (this.data != null) {
          this.platePrefix = this.data.countyPrefix;
          this.plateSuffix = this.data.licenseSuffix;

          const countryIndex = this.countryList.findIndex(
            (country) =>
              country.id.toUpperCase() == this.data.country.toUpperCase(),
          );
          // // console.log("!111!!!1!!!!1!COUN!", countryIndex)
          if (countryIndex == -1) this.selectedCountry = 0;
          else this.selectedCountry = countryIndex;
        }
      },
      immediate: true,
      deep: true,
    },
    focus: {
      handler() {
        setTimeout(() => {
          if (this.focus == this.index)
            return document.getElementById(`plate-input-${this.focus}`).focus();
        }, 10);
      },
      immediate: true,
    },
    reset() {
      this.clearPlateInput();
    },
    plateInput1() {
      this.emitChangeEvent();
    },

    plateInput2() {
      this.emitChangeEvent();
    },

    plateInput3() {
      this.emitChangeEvent();
    },
  },

  computed: {
    licensePlate() {
      var plateObj = {
        isValid: !(
          this.plateInput1 == null ||
          this.plateInput1 == '' ||
          this.plateInput2 == null ||
          this.plateInput2 == '' ||
          this.selectedCountry == null
        ),
        licensePlate:
          `${this.plateInput1 != null ? this.plateInput1.toUpperCase() : ''}-${
            this.plateInput2 != null ? this.plateInput2.toUpperCase() : ''
          }` +
          (this.plateInput3 != null && this.plateInput3.length > 0
            ? `-${this.plateInput3}`
            : ''),
        country: this.countryList[this.selectedCountry].id,
      };
      return plateObj;
    },
  },

  methods: {
    emitChangeEvent() {
      if (
        this.plateInput1 != null &&
        this.plateInput1.length > 0 &&
        this.plateInput2 != null &&
        this.plateInput2.length > 0
      )
        return this.$emit('change', {id: this.index, data: this.licensePlate});
      else return this.$emit('change', {id: this.index, data: null});
    },
    clearPlateInput() {
      this.platePrefix = null;
      this.plateSuffix = null;
      this.selectedCountry = 0;
    },
  },
};
</script>

<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(180, 180, 180, 0.5);
  opacity: 1; /* Firefox */
  user-select: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(180, 180, 180, 0.5);
  user-select: none;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(180, 180, 180, 0.5);
  user-select: none;
}

input {
  background-color: rgba(220, 220, 220, 0.4);
  border-radius: 10px;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 100ms linear;
}
input:focus {
  background-color: rgba(220, 220, 220, 1);

  outline: none;
  transition: all 100ms linear;
}
</style>
