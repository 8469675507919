<template>
  <Dialog
    :close="closeTrigger"
    :closeable="true"
    v-on:close="closeDialog()"
    width="500"
    :title="$t('tools.project.whitelists.dialog.createWhitelist.title')"
  >
    <template v-slot:button="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <IconButton
          :isIcon="true"
          :disabled="disabled"
          icon="mdi-text-box-plus"
          style="border-radius: 50%; border: 1px solid #a0a0a0"
        />
      </div>
    </template>

    <template v-slot:content>
      <TextInput
        :value="whitelistData.name"
        v-on:change="whitelistData.name = $event"
        :placeholder="
          $t('tools.project.whitelists.dialog.createWhitelist.groupName')
        "
      />

      <div style="user-select: none">
        <Checkbox
          class="mt-2 mb-1"
          :value="whitelistData.writeToDevice"
          v-on:update="whitelistData.writeToDevice = $event"
          :text="
            $t('tools.project.whitelists.dialog.createWhitelist.barrierControl')
          "
        />
        <v-alert
          text
          v-show="whitelistData.writeToDevice == true"
          filled
          dense
          style="font-size: 14px; color: rgba(0, 0, 0, 0.5) !important"
          color="grey"
          class="mt-1 ml-1 mr-1"
        >
          {{
            $t('tools.project.whitelists.dialog.createWhitelist.barrierNotice')
          }}
        </v-alert>
        <Checkbox
          class="mt-1 mb-1"
          :value="whitelistData.hasAllowedTime"
          v-on:update="whitelistData.hasAllowedTime = $event"
          :text="
            $t('tools.project.whitelists.dialog.createWhitelist.setAllowedTime')
          "
        />
      </div>
      <TextInput
        v-show="whitelistData.hasAllowedTime"
        style="max-width: 250px"
        :disabled="whitelistData.hasAllowedTime != true"
        :value="whitelistData.hasAllowedTime ? whitelistData.allowedTime : ''"
        v-on:change="whitelistData.allowedTime = $event"
        placeholder=""
        number
        :suffix="$t('helper.units.minutes.long')"
      />

      <v-divider class="mt-4 mb-4 ml-1 mr-1" style="opacity: 0.5" />

      <UploadPlatesFile
        v-on:save="uploadedPlates = $event"
        :key="updateUploadForm"
        newList
      />
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 mt-2" align="center">
        <v-spacer />
        <TextButton
          class="mr-2"
          :text="$t('helper.buttons.cancel')"
          v-on:click="closeDialog"
        />
        <TextButton
          v-on:click="createWhitelist()"
          :disabled="
            whitelistData.name == null || whitelistData.name.length == 0
          "
          :loading="loading"
          buttonColor="primary"
          :text="$t('helper.buttons.create')"
        />
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

import Dialog from '@components/dialog/Dialog.vue';
import TextInput from '../../../../../../ui-components/src/components/text/TextInput.vue';
import Checkbox from '@components/text/Checkbox.vue';
import UploadPlatesFile from './UploadPlatesFile.vue';
import IconButton from '../../../../../../ui-components/src/components/button/IconButton.vue';
export default {
  name: 'CreateWhitelist',
  components: {
    Dialog,
    TextInput,
    Checkbox,
    TextButton,
    UploadPlatesFile,
    IconButton,
  },

  props: {
    disabled: {
      default: false,
    },
  },

  data() {
    return {
      updateUploadForm: false,
      closeTrigger: false,
      loading: false,
      uploadedPlates: null,
      whitelistData: {
        name: '',
        hasAllowedTime: false,
        writeToDevice: false,
        allowedTime: 1,
      },
    };
  },

  methods: {
    async createWhitelist() {
      this.loading = true;

      var requestData = {
        name: this.whitelistData.name || '',
        writeToDevice: this.whitelistData.writeToDevice || false,
      };

      if (
        this.whitelistData.hasAllowedTime &&
        this.whitelistData.allowedTime != null &&
        this.whitelistData.allowedTime > 0
      ) {
        requestData = {
          ...requestData,
          allowedTime: parseInt(this.whitelistData.allowedTime),
        };
      }

      if (this.uploadedPlates != null) {
        requestData = {...requestData, items: this.uploadedPlates};
      } else {
        requestData = {...requestData, items: []};
      }

      const resp = await this.$api.gatereport.createWhitelist(
        this.$route.params.projectId,
        requestData,
      );

      if (resp.success == true) {
        this.$toast.info(
          this.$t('tools.project.whitelists.toasts.info.createdGroup'),
        );
        this.$emit('update');

        this.$router.replace({query: {id: resp.data.id}}).catch(() => {});
        this.closeDialog();

        setTimeout(() => {
          this.whitelistData = {
            name: '',
            hasAllowedTime: false,
            writeToDevice: false,
            allowedTime: 1,
          };

          this.uploadedPlates = null;
          this.updateUploadForm = !this.updateUploadForm;
        }, 250);
      } else {
        this.$toast.error(
          this.$t('tools.project.whitelists.toasts.error.createdGroup'),
        );
      }

      this.loading = false;
    },
    closeDialog() {
      this.closeTrigger = true;
      this.$nextTick(() => {
        this.closeTrigger = false;
      });
    },
  },
};
</script>
