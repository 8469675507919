<template>
  <div>
    <v-file-input
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      filled
      class=""
      rounded
      :hide-input="!newList"
      hide-details
      prepend-icon="mdi-upload"
      v-model="fileHandle"
      :placeholder="
        $t('tools.project.whitelists.dialog.createWhitelist.uploadVehicles')
      "
      dense
      :ripple="false"
    >
      <template v-slot:selection="{text}">
        {{ $helper.shortenText(text, 25) }}
        <b
          style="font-size: 15px"
          class="ml-2"
          v-if="newList && uploadedPlates != null"
          >({{ uploadedPlates.length }}
          {{ $t('helper.text.licensePlates') }})</b
        >
      </template>
    </v-file-input>

    <v-dialog :persisten="loading" v-model="uploadValidationDialog" width="400">
      <DialogTemplate noHeader title="">
        <template v-slot:content>
          <div class="pt-4 pb-2" style="font-size: 15px">
            {{
              $t('tools.project.whitelists.dialog.upload.text', {
                numberPlates:
                  uploadedPlates != null ? uploadedPlates.length : 'X',
              })
            }}
          </div>
        </template>

        <template v-slot:actions>
          <v-row class="ma-0" align="center" justify="end">
            <TextButton
              class="mr-2"
              dense
              v-on:click="cancelFileUpload()"
              text="Abbrechen"
            />
            <TextButton
              :loading="loading"
              v-on:click="$emit('save', uploadedPlates)"
              dense
              buttonColor="primary"
              text="Speichern"
            />
          </v-row>
        </template>
      </DialogTemplate>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file';
import DialogTemplate from '../../../../../../ui-components/src/components/dialog/DialogTemplate.vue';
import TextButton from '../../../../../../ui-components/src/components/button/TextButton.vue';

export default {
  name: 'UploadPlatesFile',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    newList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileHandle: null,
      uploadedPlates: null,
      uploadValidationDialog: false,
    };
  },
  watch: {
    loading() {
      if (this.loading == false) {
        this.uploadValidationDialog = false;
        this.fileHandle = null;
        this.uploadedPlates = null;
      }
    },
    uploadValidationDialog: {
      handler() {
        if (this.uploadValidationDialog == false) this.cancelFileUpload();
      },
      immediate: true,
      deep: true,
    },
    fileHandle: {
      async handler() {
        this.uploadedPlates = null;
        this.uploadValidationDialog = false;
        if (this.fileHandle == null) return;
        try {
          var data = await readXlsxFile(this.fileHandle);
          if (data == null || data.length == 0) {
            this.uploadedPlates = null;
            this.fileHandle = null;
            return this.$toast.error(
              this.$t('tools.project.whitelists.toasts.error.uploadNoData'),
            );
          }
          var platesHandle = [];
          data.forEach((plate) => {
            if (plate[0] != null && plate[1] != null && plate[1].length == 2) {
              if (
                !platesHandle.some(
                  (plateHandle) =>
                    plateHandle.licensePlate == plate[0] &&
                    plateHandle.country == plate[1],
                )
              ) {
                if (plate[0] != null && String(plate[0]).indexOf('-') != -1) {
                  platesHandle.push({
                    licensePlate: plate[0],
                    country: plate[1],
                  });
                }
              }
            }
          });
          if (platesHandle == null || platesHandle.length == 0) {
            this.uploadedPlates = null;
            this.fileHandle = null;
            return this.$toast.error(
              this.$t('tools.project.whitelists.toasts.error.uploadNoData'),
            );
          }
          this.uploadedPlates = platesHandle;
          if (this.newList == false) this.uploadValidationDialog = true;
          else {
            this.$emit('save', this.uploadedPlates);
          }
        } catch (error) {
          this.uploadedPlates = null;
          this.fileHandle = null;
          this.uploadValidationDialog = false;
          this.$toast.error(
            this.$t('tools.project.whitelists.toasts.error.uploadList'),
          );
          // console.log(error);
        }
      },
    },
  },

  methods: {
    cancelFileUpload() {
      this.uploadedPlates = null;
      this.fileHandle = null;
      this.uploadValidationDialog = false;
    },
  },
  components: {DialogTemplate, TextButton},
};
</script>
