<template>
  <ProjectPageTemplate style="position: relative; height: 100%; width: 100%;" :isSettings="true" :hideHeader="true"
    :title="$t(`${$route.meta.i18n}.title`)" :loading="false" :error="false">
    <template v-slot:content>
      <div style="position: relative">
        <TableTemplate :key="updateTableData" clickable :hideHeader="true" :serverSearch="true" :searchable="true"
          :group="false" :actions="false" :loading="loadingData" :headers="headers" :src="whitelistData"
          :searchText="$t('tools.project.whitelists.searchText')" v-on:close="filterTempHandle = null"
          v-on:search="searchEvent" v-on:update="getWhitelists" v-on:click="rowClickEvent">
          <template v-slot:actions>
            <CreateWhitelist :disabled="loadingData" v-show="isEditor" class="ml-0" v-on:update="getWhitelists()" />
            <v-spacer />
          </template>

          <template v-slot:barrier="{data}">
            <div style="width: 30px !important">
              <v-icon color="black" size="20" v-if="data.writeToDevice != null && data.writeToDevice == true">
                mdi-boom-gate-up</v-icon>

              <span v-else>-</span>
            </div>
          </template>

          <template v-slot:allowedTime="{data}">
            <div v-if="data.allowedTime != null && data.allowedTime != -1">
              <b><span style="margin-right: 2px; margin-left: 2px">{{
                  $helper.formatDuration(data.allowedTime)
                  }}</span>h</b>
            </div>
            <div v-else>-</div>
          </template>
        </TableTemplate>

        <v-dialog v-if="whitelistInfoDialog.data != null" v-model="whitelistInfoDialog.status" width="600">
          <WhitelistInformation :key="updatedWhitelist" :searchValue="searchValue"
            :whitelistData="whitelistInfoDialog.data" v-on:close="whitelistInfoDialog.status = false"
            style="user-select: none !important" v-on:update="getWhitelists()" />
        </v-dialog>
      </div>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import TableTemplate from '../../admin/TableTemplate.vue';
import WhitelistInformation from '../../../../components/tools/project/gatereport/dialog/WhitelistInformation.vue';
import CreateWhitelist from '../../../../components/tools/project/gatereport/dialog/CreateWhitelist.vue';

export default {
  name: 'ProjectViewWhitelist',
  components: {
    ProjectPageTemplate,
    TableTemplate,
    WhitelistInformation,
    CreateWhitelist,
  },

  data() {
    return {
      whitelistInfoDialog: {
        status: false,
        data: null,
      },
      searchValue: '',

      filterTempHandle: null,
      filterHandle: {
        sortDescending: false,
        sortBy: 0,
        minDuration: 0,
        whitelistId: null,
        violations: false,
        gate: null,
      },

      whitelistData: null,
      updatedWhitelist: false,
      updateTableData: false,
      loadingData: false,
      logs: null,
    };
  },

  computed: {
    isEditor() {
      if (this.$store.getters.user.internal == true) return true;

      if (
        this.$store.getters.user == null ||
        this.$store.getters.user.id == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general.members == null
      )
        return false;
      var user = this.$store.getters.project.general.members.reduce((a, b) =>
        a.userId == this.$store.getters.user.id ? a : b,
      );

      if (user == null || user == false) return false;
      else return this.$auth.canWrite();
    },

    headers() {
      var header = [
        {
          text: '',
          value: 'writeToDevice',
          slot: 'barrier',
          width: '65px',
          align: 'center',
          bold: true,
          sortable: false,
        },
        {
          text: this.$t('tools.project.whitelists.table.name'),
          align: 'start',
          link: true,
          value: 'name',
          bold: true,
          sortable: false,
        },
        {
          text: this.$t('tools.project.whitelists.table.allowedTime'),
          value: 'allowedTime',
          slot: 'allowedTime',
          width: '200px',
          bold: false,
          sortable: false,
        },
        {
          text: this.$t('tools.project.whitelists.table.createdAt'),
          align: 'start',
          value: 'createdAt',
          width: '200px',
          date: true,
          sortable: false,
        },
      ];

      return header;
    },

    activeFilter() {
      return (
        this.filterHandle.sortDescending != false ||
        this.filterHandle.sortBy != 0 ||
        this.filterHandle.minDuration != 0 ||
        this.filterHandle.whitelistId != null ||
        this.filterHandle.violations != false ||
        this.filterHandle.gate != null
      );
    },
  },

  created() {
    this.whitelistData = this.$store.getters.projectWhitelists;

    this.getWhitelists();
  },

  watch: {
    'whitelistInfoDialog.status': function () {
      if (this.whitelistInfoDialog.status == false)
        return setTimeout(() => {
          this.updatedWhitelist = !this.updatedWhitelist;
        }, 250);
    },
  },

  methods: {
    async searchEvent(eventHandle) {
      this.loadingData = true;
      if (eventHandle == null || eventHandle.length == 0) {
        this.searchValue = null;
        return this.getWhitelists();
      } else {
        this.searchValue = eventHandle;

        var res = await this.$api.gatereport.whitelistByPlate(
          this.$route.params.projectId,
          eventHandle,
        );

        if (res.success == true && res.data != null) {
          if (res.data.count > 0 && res.data.whitelists != null) {
            this.whitelistData = res.data.whitelists;
          } else {
            this.whitelistData = null;
          }
        }
      }

      this.loadingData = false;
    },
    rowClickEvent(eventHandle) {
      this.whitelistInfoDialog.data = JSON.parse(JSON.stringify(eventHandle));
      this.whitelistInfoDialog.status = true;
    },

    async getWhitelists() {
      this.loadingData = true;

      const resp = await this.$api.gatereport.whitelists(
        this.$route.params.projectId,
      );

      if (resp.success == true && resp.data != null) {
        this.whitelistData = resp.data.whitelists;
      } else {
        this.whitelistData = null;
      }

      if (
        this.whitelistInfoDialog.status == true &&
        this.whitelistData != null
      ) {
        var dialogList = this.whitelistData.reduce((a, b) =>
          a.id == this.whitelistInfoDialog.data.id ? a : b,
        );

        if (
          dialogList == null ||
          dialogList == false ||
          dialogList.id != this.whitelistInfoDialog.data.id
        ) {
          this.whitelistInfoDialog.status = false;
          this.$nextTick(() => {
            this.whitelistInfoDialog.data = null;
          });
        } else {
          this.whitelistInfoDialog.data = dialogList;
          this.whitelistInfoDialog.status = true;
        }
      }

      this.loadingData = false;

      this.$nextTick(() => {
        this.updateTableData = !this.updateTableData;

        this.loadingData = false;

        if (this.whitelistData != null && this.whitelistData.length > 0) {
          if (this.$route.query != null && this.$route.query.id != null) {
            var result = this.whitelistData.reduce((a, b) =>
              a.id == this.$route.query.id ? a : b,
            );

            if (
              result == null ||
              result == false ||
              result.id != this.$route.query.id
            ) {
              this.$toast.error(
                this.$t('tools.project.whitelists.toasts.error.queryDialog'),
              );

              this.$router.replace({}).catch(() => {});
            } else {
              this.rowClickEvent(result);
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.dashboard-area {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}
</style>
