<template>
  <DialogTemplate :padding="false" :title="
      $t('tools.project.whitelists.dialog.whitelist.title', {
        name:
          data != null && data.name != null && data.name.length > 0
            ? `${$helper.shortenText(data.name, 40)}`
            : '',
      })
    " v-on:close="$emit('close')">
    <template v-slot:content>
      <Tabs class="mt-1" :tabs="[
          {
            name: $t('tools.project.whitelists.dialog.whitelist.tab.general'),
            id: 'general',
          },
          {
            name: $t('tools.project.whitelists.dialog.whitelist.tab.vehicles'),
            id: 'reports',
            disabled: loadingData == true,
          },
        ]">
        <template v-slot:general>
          <div v-if="data != null" style="position: relative; height: 330px" class="ml-3 mr-3 mt-2 pt-2">
            <TableListRowEditable :width="400" :error="editFields.error == 'name'" :disabled="!isEditor"
              :loading="editFields.loading == 'name'" v-on:save="updateWhitelistDataEvent('name')"
              v-on:open="editFields.value = data.name"
              :descr="$t('tools.project.whitelists.dialog.whitelist.groupName')"
              :value="data.name || (editFields.loading ? editFields.value : '')">
              <template v-slot:input>
                <div v-if="typeof editFields.value == 'string'">
                  <TextInput v-if="typeof editFields.value == 'string'" class="ml-0 pt-2 pb-2" :placeholder="
                      $t('tools.project.whitelists.dialog.whitelist.groupName')
                    " :value="
                      editFields.value == null &&
                      editFields.value != true &&
                      editFields.value != false
                        ? ''
                        : editFields.value || ''
                    " v-on:change="editFields.value = $event || ''" />
                </div>
              </template>
            </TableListRowEditable>

            <TableListRowEditable :disabled="true" :descr="$t('tools.project.whitelists.dialog.whitelist.id')"
              slotValue>
              <template v-slot:value>{{ $helper.formatID(data.id) }}</template>
            </TableListRowEditable>

            <TableListRowEditable :disabled="true" :descr="$t('tools.project.whitelists.dialog.whitelist.createdAt')"
              slotValue>
              <template v-slot:value>{{
                $helper.formatDate(data.createdAt, true, false)
                }}</template>
            </TableListRowEditable>

            <TableListRowEditable :width="350" :error="editFields.error == 'writeToDevice'"
              :loading="editFields.loading == 'writeToDevice'" :disabled="true"
              v-on:save="updateWhitelistDataEvent('writeToDevice')"
              v-on:open="editFields.value = data.writeToDevice || false"
              :descr="$t('tools.project.whitelists.dialog.whitelist.barrier')" :listener="true" slotValue :value="
                data.writeToDevice ||
                (editFields.loading ? editFields.value : '')
              ">
              <template v-slot:value>
                <div v-if="data.writeToDevice != true" class="font-weight-normal grey--text" style="
                    font-size: 14px;
                    display: inline-block;
                    font-weight: 500;
                  ">
                  {{
                  $t(
                  'tools.project.whitelists.dialog.whitelist.noBarrierControl',
                  )
                  }}
                </div>
                <div v-else style="display: inline-block">
                  <v-row style="font-size: 13px" class="ma-0 success--text" align="center">
                    <v-icon class="mr-1" color="success" size="17">mdi-check-circle</v-icon>
                    <b>{{
                      $t(
                      'tools.project.whitelists.dialog.whitelist.barrierControl',
                      )
                      }}</b>
                  </v-row>
                </div>
              </template>
              <template v-slot:input>
                <v-alert text filled dense style="
                    font-size: 14px;
                    max-width: 400px;
                    color: rgba(0, 0, 0, 0.5) !important;
                  " color="grey" class="mt-3">
                  {{
                  $t(
                  'tools.project.whitelists.dialog.whitelist.barrierInfoText',
                  )
                  }}
                </v-alert>
                <v-radio-group class="pb-3" hide-details v-model="editFields.value">
                  <v-radio hide-details dense :ripple="false" :value="true">
                    <template v-slot:label>
                      <div :class="editFields.value != true ? '' : 'primary--text'">
                        {{
                        $t(
                        'tools.project.whitelists.dialog.whitelist.selectionBarrier.active',
                        )
                        }}
                      </div>
                    </template>
                  </v-radio>
                  <v-radio small hide-details dense :ripple="false" :label="
                      $t(
                        'tools.project.whitelists.dialog.whitelist.selectionBarrier.inactive',
                      )
                    " :value="false"></v-radio>
                </v-radio-group>
              </template>
            </TableListRowEditable>

            <TableListRowEditable :width="270" :error="editFields.error == 'allowedTime'"
              :loading="editFields.loading == 'allowedTime'" :disabled="!isEditor"
              v-on:save="updateWhitelistDataEvent('allowedTime')" v-on:open="editFields.value = data.allowedTime"
              :descr="
                $t('tools.project.whitelists.dialog.whitelist.allowedTime')
              " slotValue :value="
                data.allowedTime || (editFields.loading ? editFields.value : '')
              ">
              <template v-slot:value>
                <span v-if="data.allowedTime != null && data.allowedTime > 0">
                  {{ $helper.formatDuration(data.allowedTime) }} h</span>
                <span v-else class="font-weight-normal grey--text" style="
                    font-size: 14px;
                    display: inline-block;
                    font-weight: 500;
                  ">
                  {{
                  $t(
                  'tools.project.whitelists.dialog.whitelist.noAllowedTime',
                  )
                  }}
                </span>
              </template>

              <template v-slot:input>
                <TextInput class="ml-0 pt-2 pb-2" number :placeholder="
                    $t('tools.project.whitelists.dialog.whitelist.allowedTime')
                  " :numberBounds="{min: 0, max: 100000}" style="max-width: 250px"
                  :suffix="$t('helper.units.minutes.long')" :value="
                    editFields.value == null ? '' : editFields.value || ''
                  " v-on:change="editFields.value = $event || ''" />
              </template>
            </TableListRowEditable>

            <!-- <div class="grey lighten-4 mt-3 pt-1" style="user-select: none!important; border: 1px solid #E0E0E0!important; position: relative; height: 110px; border-radius: 10px; width: 100%; ">

        </div> -->
          </div>
        </template>

        <template v-slot:reports>
          <div style="position: relative; height: 330px" class="ml-3 mr-3 pt-2 mt-2 pl-1 pr-1">
            <div v-if="!loadingData" class="grey lighten-4 pt-1" style="
                user-select: none !important;
                border: 1px solid #e0e0e0 !important;
                position: relative;
                height: 321px;
                border-radius: 10px;
                width: 100%;
              ">
              <v-row class="ma-0 mt-1" align="center">
                <TextInput icon="mdi-magnify" clearable :disabled="vehicleData == null" class="ml-2"
                  style="max-width: 250px" :placeholder="
                    $t('tools.project.whitelists.dialog.whitelist.searchText')
                  " :value="searchPlates" v-on:change="searchPlates = $event" />

                <div v-if="items != null && items.length > 1" style="font-size: 15px" class="ml-3">
                  (<b>{{ items.length }}</b>
                  <span>{{ $t('helper.text.vehicles') }}</span>)
                </div>
                <v-spacer />
                <UploadPlatesFile v-show="isEditor" :key="resetUploadTrigger" class="mb-0 mr-1"
                  v-on:save="uploadedFilesList" :loading="loadingFileUpload" />
                <v-menu v-if="isEditor" v-model="creationMenu" :close-on-content-click="false" :nudge-width="400"
                  :nudge-left="480" offset-x transition="none">
                  <template v-slot:activator="{on, attrs}">
                    <v-btn :style="
                        creationMenu
                          ? 'border-radius: 50%; background-color: #E0E0E0!important'
                          : ''
                      " class="mr-3 mt-1" icon :loading="loadingCreation" :ripple="false" v-bind="attrs" v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <v-card style="user-select: none">
                    <div class="pt-3 pb-2 pl-4 pr-4">
                      <b>{{
                        $t(
                        'tools.project.whitelists.dialog.whitelist.addLicensePlateHeader',
                        )
                        }}:</b>
                      <PlateInput :key="updateData" v-on:change="plateInputEvent" class="mt-1" />
                    </div>
                    <div v-show="doesExist">
                      <v-alert text filled hide-details dense style="font-size: 14px; margin-bottom: 3px !important"
                        color="grey" class="mt-1 ml-3 mr-3">
                        <v-row class="ma-0" align="center">
                          <v-icon size="17" color="primary">mdi-information</v-icon>
                          <div style="font-size: 14px" class="font-weight-bold ml-2 primary--text">
                            {{
                            $t(
                            'tools.project.whitelists.dialog.whitelist.plateExists',
                            )
                            }}
                          </div>
                        </v-row>
                      </v-alert>
                    </div>
                    <!-- 
                                        <Checkbox :key="applyPlateAll" class="mt-0 mb-3 ml-3"
                                            :style="(doesExist || creationLicensePlate == null || creationLicensePlate.data == null || creationLicensePlate.data.licensePlate == 0) ? 'opacity: .5' : ''"
                                            :disabled="doesExist || creationLicensePlate == null || creationLicensePlate.data == null || creationLicensePlate.data.licensePlate == 0"
                                            v-on:update="applyPlateAll = $event" :src="applyPlateAll"
                                            :text="$t('tools.project.log.table.addListDialog.checkbox')" /> -->

                    <v-card-actions class="pt-2">
                      <v-spacer />
                      <TextButton v-on:click="creationMenu = false" dense class="mr-2"
                        :text="$t('helper.buttons.cancel')" />

                      <TextButton :disabled="
                          doesExist ||
                          creationLicensePlate == null ||
                          creationLicensePlate.data == null ||
                          creationLicensePlate.data.licensePlate == 0
                        " v-on:click="addNewLicensePlate()" dense buttonColor="primary"
                        :text="$t('helper.buttons.submit')" />
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-row>

              <v-divider class="mt-2 mb-1 mr-2 ml-2" />

              <div v-if="vehicleData == null">
                <v-col class="ma-0 pb-3" align="center" justify="center">
                  <span style="font-size: 15px" class="grey--text">{{
                    $t(
                    'tools.project.whitelists.dialog.whitelist.noVehiclesSet',
                    )
                    }}</span>
                </v-col>
              </div>

              <div v-else class="pb-1">
                <div style="height: 240px; width: 100%">
                  <div v-if="items == null || items.length == 0">
                    <v-row class="ma-0 pt-2" align="center" justify="center">
                      <span style="font-size: 15px" class="grey--text">{{
                        $t(
                        'tools.project.whitelists.dialog.whitelist.noVehiclesFound',
                        )
                        }}</span>
                    </v-row>
                  </div>
                  <v-virtual-scroll :bench="10" :items="items" height="255" item-height="51">
                    <template v-slot:default="{item, index}">
                      <v-list-item :key="index" class="list-item">
                        <v-list-item-content>
                          <v-list-item-title style="height: 17px">
                            <b style="font-size: 15px">{{
                              item.licensePlate
                              }}</b>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span style="font-size: 13px">{{
                              $helper.countryName(item.country)
                              }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-row class="ma-0" align="center">
                            <IconButton class="mr-1" v-on:click="
                                $router.push({
                                  name: 'project_violations',
                                  query: {plate: item.licensePlate},
                                })
                              " dense icon="mdi-car-clock" />
                            <IconButton v-on:click="
                                $router.push({
                                  name: 'project_gate_report',
                                  query: {plate: item.licensePlate},
                                })
                              " dense icon="mdi-magnify" />
                            <IconButton class="ml-2" v-show="isEditor" v-on:click="showDeleteDialog(item)" dense
                              icon="mdi-close-circle" />
                          </v-row>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Tabs>

      <v-dialog v-show="isEditor" width="400" :persistent="loadingDelete" v-model="deleteDialog.status">
        <DeleteDialogTemplate v-on:delete="deletePlateWhitelist" v-on:close="deleteDialog.status = false"
          :title="$t('tools.project.whitelists.dialog.deleteVehicle.title')" :loading="loadingDelete">
          <template v-slot:content>{{
            $t('tools.project.whitelists.dialog.deleteVehicle.text', {
            plate: deleteDialog.data.licensePlate,
            })
            }}</template>
        </DeleteDialogTemplate>
      </v-dialog>

      <v-dialog v-show="isEditor" v-model="deleteWhitelist" width="450">
        <DeleteDialogTemplate :disabled="deleteValidation.toUpperCase() != data.name.toUpperCase()"
          v-on:delete="deleteWhitelistEvent" :loading="loadingDelete"
          :title="$t('tools.project.whitelists.dialog.deleteWhitelist.title')" v-on:close="deleteWhitelist = false">
          <template v-slot:content>
            <span style="font-size: 15px">
              <span>
                {{ $t('tools.project.whitelists.dialog.deleteWhitelist.text') }}
              </span>
              <br />
              <br /><b>
                {{
                $t(
                'tools.project.whitelists.dialog.deleteWhitelist.validateText',
                )
                }}
              </b>
            </span>
            <TextInput class="mt-3 mb-3" style="text-transform: uppercase !important" :inverseValidation="true"
              :validate="
                deleteValidation != null && deleteValidation.length > 0
              " :data="[data.name.toUpperCase()]" :value="deleteValidation"
              v-on:change="deleteValidation = $event.toUpperCase()" :placeholder="data.name" />
          </template>
        </DeleteDialogTemplate>
      </v-dialog>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0" align="center">
        <IconButton :disabled="!isEditor" v-on:click="deleteWhitelist = !deleteWhitelist" icon="mdi-delete" />

        <v-spacer />
        <v-fade-transition>
          <div class="mr-2" style="margin-bottom: 2px" v-show="loadingData || errorLoading">
            <v-progress-circular v-show="loadingData" indeterminate size="20" width="2" color="primary" />

            <v-icon v-show="errorLoading" color="error">mdi-alert-circle</v-icon>
          </div>
        </v-fade-transition>

        <v-menu :disabled="errorLoading || loadingData" :nudge-width="175" :nudge-right="8" offset-x transition="none">
          <template v-slot:activator="{on, attrs}">
            <v-btn :disabled="errorLoading || loadingData" class="mr-3" icon :ripple="false" v-bind="attrs" v-on="on">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <v-list class="pa-2">
            <v-list-item :ripple="false" style="
                border-radius: 5px !important;
                font-size: 14px;
                overflow: hidden !important;
              " :style="
                vehicleData == null || vehicleData.length == 0
                  ? 'opacity: .2; pointer-events: none'
                  : ''
              " v-on:click="downloadWhitelistData(1)" dense>
              <v-row class="ma-0" align="center">
                <v-icon size="17" class="mr-2">mdi-table-arrow-down</v-icon>
                <b class="mr-1">{{
                  $t('tools.project.whitelists.buttons.download.spreadsheet')
                  }}</b>
                (*.xlsx)
              </v-row>
            </v-list-item>

            <v-list-item :ripple="false" style="
                border-radius: 5px !important;
                font-size: 14px;
                overflow: hidden !important;
              " :style="
                vehicleData == null || vehicleData.length == 0
                  ? 'opacity: .2; pointer-events: none'
                  : ''
              " v-on:click="downloadWhitelistData(2)" dense>
              <v-row class="ma-0" align="center">
                <v-icon size="17" class="mr-2">mdi-file-download</v-icon>
                <b class="mr-1">{{
                  $t('tools.project.whitelists.buttons.download.report')
                  }}</b>
                (*.pdf)
              </v-row>
            </v-list-item>

            <v-list-item :ripple="false" style="
                border-radius: 5px !important;
                font-size: 14px;
                overflow: hidden !important;
              " :style="
                vehicleData == null || vehicleData.length == 0
                  ? 'opacity: .2; pointer-events: none'
                  : ''
              " v-on:click="downloadWhitelistData(3)" dense>
              <v-row class="ma-0" align="center">
                <v-icon size="17" class="mr-2">mdi-code-braces-box</v-icon>
                <b class="mr-1">{{
                  $t('tools.project.whitelists.buttons.download.json')
                  }}</b>
                (*.json)
              </v-row>
            </v-list-item>
          </v-list>
        </v-menu>

        <TextButton v-on:click="
            $router.push({
              name: 'project_gate_report',
              query: {whitelistId: data.id},
            })
          " icon="mdi-text-box-search" buttonColor="#E0E0E0"
          :text="$t('tools.project.whitelists.buttons.searchProtocol')" />
      </v-row>
    </template>
  </DialogTemplate>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import TableListRowEditable from '@components/table/TableListRowEditable.vue';
import TextInput from '@components/text/TextInput.vue';
import IconButton from '@components/button/IconButton.vue';
import TextButton from '@components/button/TextButton.vue';
import PlateInput from '../PlateInput.vue';
import DeleteDialogTemplate from '@components/dialog/DeleteDialogTemplate.vue';
import Tabs from '@components/list/Tabs.vue';
// import Checkbox from '@components/text/Checkbox.vue';
import UploadPlatesFile from './UploadPlatesFile.vue';

export default {
  name: 'WhitelistInformation',
  components: {
    DialogTemplate,
    TableListRowEditable,
    TextInput,
    IconButton,
    TextButton,
    PlateInput,
    DeleteDialogTemplate,
    Tabs,
    // Checkbox,
    UploadPlatesFile,
  },

  props: {
    whitelistData: {
      required: true,
    },
    searchValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      loadingFileUpload: false,
      applyPlateAll: false,
      deleteValidation: '',
      deleteWhitelist: false,
      updateData: false,
      creationLicensePlate: null,
      creationMenu: false,
      deleteDialog: {
        status: false,
        data: null,
      },
      data: this.whitelistData,
      errorLoading: false,
      loadingData: false,
      loadingCreation: false,
      loadingDelete: false,
      vehicleData: null,
      searchPlates: '',
      editFields: {
        value: '',
        loading: false,
        error: false,
      },
      resetUploadTrigger: false,
    };
  },

  computed: {
    isEditor() {
      if (this.$store.getters.user.internal == true) return true;

      if (
        this.$store.getters.user == null ||
        this.$store.getters.user.id == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general.members == null
      )
        return false;
      var user = this.$store.getters.project.general.members.reduce((a, b) =>
        a.userId == this.$store.getters.user.id ? a : b,
      );

      if (user == null || user == false) return false;
      else return this.$auth.canWrite();
    },

    doesExist() {
      if (
        this.creationLicensePlate == null ||
        this.creationLicensePlate.data == null ||
        this.creationLicensePlate.data.isValid == false ||
        this.items == null
      )
        return false;
      else {
        if (
          this.items.filter(
            (item) =>
              item.licensePlate.toUpperCase() ==
                this.creationLicensePlate.data.licensePlate.toUpperCase() &&
              item.country.toUpperCase() ==
                this.creationLicensePlate.data.country.toUpperCase(),
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    items() {
      if (this.vehicleData == null) return null;

      if (this.searchPlates != null && this.searchPlates.length > 0) {
        return this.vehicleData.filter((vehicle) =>
          vehicle.licensePlate
            .toLowerCase()
            .includes(this.searchPlates.toLowerCase()),
        );
      } else {
        return this.vehicleData;
      }
    },
  },

  watch: {
    doesExist() {
      if (this.doesExist == true) {
        this.applyPlateAll = false;
      }
    },
    deleteWhitelist: {
      handler() {
        if (this.deleteWhitelist == false) {
          setTimeout(() => {
            this.deleteValidation = '';
          }, 250);
        }
      },
      immediate: true,
      deep: true,
    },
    whitelistData: {
      handler() {
        if (this.data != null) {
          this.editFields.loading = false;
          this.data = this.whitelistData;
          return this.loadWhitelistData(false);
        }
      },
    },

    data: {
      handler() {
        return this.loadWhitelistData(false);
      },
      immediate: true,
      deep: true,
    },

    searchValue: {
      handler() {
        this.searchPlates = this.searchValue;
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    async uploadedFilesList(platesHandle) {
      if (platesHandle == null) return;

      this.loadingFileUpload = true;

      this.$nextTick(async () => {
        var filteredPlates = [];
        platesHandle.forEach((plate) => {
          if (
            !(
              this.vehicleData != null &&
              this.vehicleData.length > 0 &&
              this.vehicleData.some(
                (item) => item.licensePlate == plate.licensePlate,
              )
            )
          ) {
            filteredPlates.push(plate);
          }
        });

        if (filteredPlates.length == 0) {
          this.$toast.info(
            this.$t('tools.project.whitelists.toasts.info.allVehiclesExists'),
          );
        } else {
          const resp = await this.$api.gatereport.addVehicleWhitelist(
            this.$route.params.projectId,
            this.data.id,
            filteredPlates,
            true,
          );

          if (resp.success == true) {
            await this.loadWhitelistData(false);

            this.$toast.info(
              this.$t('tools.project.whitelists.toasts.info.addedVehicles', {
                total: filteredPlates.length,
              }),
            );
          } else {
            this.$toast.error(
              this.$t(
                'tools.project.whitelists.toasts.error.cannotAddVehicles',
              ),
            );
          }
        }

        this.loadingFileUpload = false;
        this.resetUploadTrigger = !this.resetUploadTrigger;
      });
    },
    async deleteWhitelistEvent() {
      this.loadingDelete = true;
      const resp = await this.$api.gatereport.deleteWhitelist(
        this.$route.params.projectId,
        this.data.id,
      );

      if (resp.success == true) {
        this.$toast.info(
          this.$t('tools.project.whitelists.toasts.info.groupRemoved'),
        );
        this.$emit('update');
        this.$emit('close');
      } else {
        this.$toast.error(
          this.$t('tools.project.whitelists.toasts.error.groupRemoved'),
        );
      }

      this.loadingDelete = false;
    },
    downloadWhitelistData(type) {
      switch (type) {
        case 1:
          this.$helper.downloadXLSX(
            'export-report-',
            null,
            [
              {
                value: 'licensePlate',
                text: this.$t('tools.project.whitelists.table.licensePlate'),
                width: 20,
              },
              {
                value: 'country',
                text: this.$t('tools.project.whitelists.table.licensePlate'),
                width: 10,
              },
            ],
            this.vehicleData,
          );
          break;
        case 2:
          this.$pdf.createWhitelistTable(this.data, this.vehicleData);
          break;
        case 3:
          this.$helper.downloadJSON(
            {whitelist: this.data, vehicles: this.vehicleData},
            `export-whitelist-${this.data.name}-${+new Date()}`,
          );
          break;

        default:
          break;
      }
    },
    plateInputEvent(eventHandle) {
      this.creationLicensePlate = eventHandle;
    },
    async addNewLicensePlate() {
      this.loadingCreation = true;
      this.creationMenu = false;
      const resp = await this.$api.gatereport.addVehicleWhitelist(
        this.$route.params.projectId,
        this.data.id,
        [
          {
            licensePlate: this.creationLicensePlate.data.licensePlate,
            country: this.creationLicensePlate.data.country,
          },
        ],
        this.applyPlateAll,
      );

      if (resp.success == true) {
        await this.loadWhitelistData(false);
        this.$toast.info(
          this.$t('tools.project.whitelists.toasts.info.addedSingleVehicle', {
            plate: this.creationLicensePlate.data.licensePlate,
          }),
        );
        this.creationLicensePlate = null;
      } else {
        this.$toast.error(
          this.$t('tools.project.whitelists.toasts.error.addedSingleVehicle'),
        );
      }

      this.updateData = !this.updateData;
      this.loadingCreation = false;
    },
    async deletePlateWhitelist() {
      this.loadingDelete = true;

      const resp = await this.$api.gatereport.deletePlateWhitelist(
        this.$route.params.projectId,
        this.data.id,
        this.deleteDialog.data.licensePlate,
      );

      if (resp.success == true) {
        await this.loadWhitelistData(false);
      } else {
        this.$toast.error(
          this.$t('tools.project.whitelists.toasts.error.deleteVehicle'),
        );
      }
      this.loadingDelete = false;
      this.deleteDialog.status = false;
    },
    async showDeleteDialog(data) {
      this.deleteDialog.data = data;
      this.deleteDialog.status = true;
    },

    async loadWhitelistData(loading = true) {
      if (loading == true) this.loadingData = true;

      this.errorLoading = false;
      var resp = await this.$api.gatereport.whitelist(
        this.$route.params.projectId,
        this.data.id,
      );
      if (resp.success == true) {
        this.vehicleData = resp.data.items;
      } else {
        this.vehicleData = null;
        this.errorLoading = true;
      }
      this.loadingData = false;
    },

    inputUpdateValidation(type, data, isError) {
      if (this.editFields.value == this.data[type]) {
        this.editFields.error = false;
        this.editFields.loading = false;
        return;
      }
      if (this.editFields.value == null || this.editFields.value.length == 0) {
        this.editFields.loading = false;
        return this.$toast.error(
          this.$t(
            'tools.management.devices.dialog.information.toast.noEmptyFields',
          ),
        );
      }

      this.editFields.error = false;
      this.editFields.loading = type;

      if (data != this.editFields.value || isError) {
        return true;
      }

      return false;
    },

    async updateWhitelistDataEvent(type, isError = false) {
      var checkedInput = this.inputUpdateValidation(
        type,
        this.data[type],
        isError,
      );

      if (checkedInput == true) {
        const prevData = this.data[type];

        try {
          this.data[type] = this.editFields.value;

          var resp = await this.$api.gatereport.updateWhitelist(
            this.$route.params.projectId,
            this.data.id,
            this.data,
          );

          if (resp.success == true) {
            this.$emit('update');
            this.$toast.info(
              this.$t('tools.project.whitelists.toasts.info.changedGroupData'),
            );
          } else {
            throw new Error();
          }
        } catch {
          this.data[type] = prevData;
          this.editFields.error = type;
          this.$toast.error(
            this.$t('tools.project.whitelists.toasts.error.changedGroupData'),
          );
        }
      }
    },
  },
};
</script>

<style>
.list-item {
  background-color: #efefef;
  max-height: 40px;
  border-radius: 10px;
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 10px !important;
  overflow: hidden;
  cursor: default !important;
}

.list-item:hover {
  background-color: #eaeaea;
}
</style>
